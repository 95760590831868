<template>
  <div class="edit-profile">
    <div class="edit-profile__header">
      <user-avatar :data="form.avatar" @update="form.avatar = $event" />
      <div class="edit-profile__header-rang">
        <img :src="getRole.icon" alt=""/>
        {{ $t(getRole.text) }}
      </div>
    </div>
    <div class="edit-profile__line"></div>
    <div class="edit-profile__form">
      <ui-input
          v-if="getUser?.role === 'Moderator' || getUser?.role === 'Admin' || getUser?.role === 'Active'"
          v-model="form.lastName"
          :label="$t('last-name')"
          :required-field="getUser?.role !== 'Active'"
          :error="$v.form.lastName?.$error"
      />
      <ui-input
          v-if="getUser?.role === 'Moderator' || getUser?.role === 'Admin' || getUser?.role === 'Active'"
          v-model="form.firstName"
          :label="$t('first-name')"
          :error="$v.form.firstName?.$error"
          :required-field="getUser?.role !== 'Active'"
      />
      <ui-input
          v-model="form.middleName"
          :label="$t('middle-name')"
          v-if="getUser?.role === 'Moderator' || getUser?.role === 'Admin' || getUser?.role === 'Active'"
      />
      <ui-input
          v-model="form.login"
          :label="$t('nickname')"
          required-field
          :error="$v.form.login?.$error || !!errorEmail"
          :error-text="errorEmail"
          @input="errorEmail = null"
      />
      <ui-date-picker
          v-model="form.birthDate"
          :label="$t('date-of-birth')"
          :placeholder="$t('date-format')"
          v-if="getUser?.role === 'Active'"
      />
      <div class="edit-profile__form-multiselect" v-if="getUser?.role === 'Inspector' || getUser?.role === 'Moderator' || getUser?.role === 'Admin' || getUser?.role === 'Lawyer' || getUser?.role === 'Active'">
        <label class="typo__label" for="multiSelect">{{ $t('place-of-residence') }}</label>
        <Multiselect
            @search-change="getCityApi"
            id="multiSelect"
            v-model="form.city"
            :options="regions"
            placeholder=""
            :loading="isLoadingMultiSelect"
            :custom-label="getCityName"
            :close-on-select="true"
            :show-labels="false"
        >
          <template slot="noOptions" >
            {{ $t('enter-address') }}
          </template>
        </Multiselect>
      </div>
      <ui-input
          v-model="form.email"
          label="E-mail"
          required-field
          read-only
          v-if="getUser?.role === 'Moderator' || getUser?.role === 'Admin'"
      />
      <ui-input
          v-model="form.phone"
          :label="getPhoneLabel"
          :required-field="getUser?.role !== 'Active'"
          v-mask="'+7(###) ###-##-##'"
          placeholder="+7(XXX) XXX-XX-XX"
          :error="$v.form.phone?.$error"
      />
      <div class="edit-profile__form-upload upload" v-if="getUser?.role !== 'Moderator' && getUser?.role !== 'Admin' && getUser?.role !== 'Active'">
        <p :class="['upload__text', { error: $v.form.documentFile?.$error }]">{{ $t('documents') }}</p>
        <!--        <p class="upload__title">Документы</p>-->
        <div class="upload__wrap">
          <label>
            <input type="file" ref="file" @input="uploadDocument" accept="image/*" >
            <span class="upload__wrap-btn">
              <img src="@/assets/svg/icon-plus-white.svg" alt="" />
            </span>
          </label>
          <a
              v-for="(item, key) in form.documentSrc"
              :key="key"
              :href="item.fileSrc || item.url"
              target="_blank"
              style="display: flex"
              class="upload__wrap-file"
          >
            <img
                src="@/assets/svg/icon-docs-grey.svg"
                alt=""
            />
            <div class="upload__wrap-file-close" v-if="!item.fileSrc" @click="deleteFile($event, {file: item.file, filename: item.filename})">
              <img
                  src="@/assets/svg/close/white.svg"
                  alt=""
              />
            </div>
            <div class="upload__wrap-file-info">
              <p>{{ item.name || item.originalname }}</p>
              <span>{{ getFullDate(item.createdAt || new Date())}}</span>
            </div>
          </a>
        </div>
      </div>
    </div>
    <div class="edit-profile__line"></div>
    <ui-button
        @click="submit"
        :disabled="$v.form.$anyError || changedForm || !!errorEmail"
    >
      {{ $t('save') }}
    </ui-button>

    <ui-alert v-model="visibleSuccessAlert">
      <p class="ui-alert__title">{{ $t('changes-were-successfully-saved') }}</p>
      <ui-button @click="editProfile">{{ $t('continue') }}</ui-button>
    </ui-alert>
  </div>
</template>


<script>
import UiButton from "@/components/ui/UiButton";
import UiInput from "@/components/ui/UiInput";
import {mapActions, mapGetters, mapMutations} from "vuex";
import {getUserRole} from "@/utils/user";
import UserAvatar from "@/components/common/UserAvatar";
import {minLength, required} from "vuelidate/lib/validators";
import { getDate, removeFormat, formatPhone, getFullDate } from '@/utils/general'
import Multiselect from "vue-multiselect";
import axios from "axios";
import UiDatePicker from "@/components/ui/UiDatePicker.vue";

let formCopy = {}

export default {
  name: "EditProfile",
  components: {
    UiDatePicker,
    Multiselect,
    UserAvatar,
    UiButton,
    UiInput,
    UiAlert: () => import('@/components/ui/UiAlert'),
  },

  data() {
    return {
      form: {},
      visibleSuccessAlert: false,
      errorEmail: '',
      getFullDate,
      isDeleteFile: false,
      regions: [],
      isLoadingMultiSelect: false,
    }
  },

  watch: {
    getUser() {
      this.collectForm()
    }
  },

  mounted() {
    this.collectForm()
    console.log(this.form.birthDate)
  },

  computed: {
    ...mapGetters([
      'getUser',
      'getRegions'
    ]),

    changedForm() {
      return JSON.stringify(this.form) === JSON.stringify(formCopy)
    },

    getRole() {
      return getUserRole(this.getUser?.role)
    },

    getDocumentFileText() {
      return this.getUser?.role === 'Inspector' ? this.$t('attach-your-ID') : '' ||
      this.getUser?.role === 'Company' ? this.$t('attach-primary-state-registration') : '' ||
      this.getUser?.role === 'Lawyer' ? this.$t('attach-document') : '' ||
      this.getUser?.role === 'Government' ? this.$t('attach-document') : ''
    },

    getRules() {
      return this.getUser?.role === 'Inspector' || this.getUser?.role === 'Lawyer' ? this.inspectorRules : '' ||
      this.getUser?.role === 'Company' || this.getUser?.role === 'Government' ? this.companyRules : '' ||
      this.getUser?.role === 'Admin' || this.getUser?.role === 'Moderator' ? this.moderatorAdminRules : '' ||
      this.getUser?.role === 'Active' ? this.activeRules : ''
    },

    inspectorRules() {
      return {
        login: {
          required,
        },
        phone: {
          required,
          minLength: minLength(17)
        },
        documentFile: {
          required,
        },
      }
    },

    companyRules() {
      return {
        login: {
          required,
        },
        firstName: {
          required
        },
        lastName: {
          required
        },
        phone: {
          required,
          minLength: minLength(17)
        },
      }
    },

    moderatorAdminRules() {
      return {
        login: {
          required,
        },
        firstName: {
          required
        },
        lastName: {
          required
        },
        phone: {
          required,
          minLength: minLength(17)
        },
        city: {
          required
        },
        email: {
          required
        }
      }
    },

    activeRules() {
      return {
        login: {
          required,
        },
        city: {
          required
        },
        phone: {
          minLength: minLength(17)
        },
      }
    },

    getPhoneLabel () {
      return this.getUser?.role === 'Company' || this.getUser?.role === 'Government' ? this.$t("representative's-phone-number") : this.$t('phone-number')
    }
  },

  methods: {
    ...mapMutations(['setUserData']),
    ...mapActions(['update', 'reqUpload']),

    dataFormat() {
      function formatDate(date) {
        let d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
          month = '0' + month;
        if (day.length < 2)
          day = '0' + day;

        return [year, month, day].join('-');
      }

      return this.form.birthDate !== null ? formatDate(this.form.birthDate) : null
    },

    uploadDocument({ target }) {
      this.reqUpload({
        file: target.files[0],
        type: "document",
      }).then((res) => {
        this.form.documentFile.push(res.data.filename)
        this.form.documentSrc.push(res.data)
        this.$refs.file.value = null
      })
      console.log(this.form.documentSrc)
    },

    deleteFile(e, data) {
      e.preventDefault();
      if(data.file) {
        this.form.documentFile = this.form.documentFile.filter((elem) => elem !== data.file);
        this.form.documentSrc = this.form.documentSrc.filter((elem) => elem.file !== data.file);
        this.isDeleteFile = true;
      }else if(data.filename) {
        this.form.documentFile = this.form.documentFile.filter((elem) => elem !== data.filename);
        this.form.documentSrc = this.form.documentSrc.filter((elem) => elem.filename !== data.filename);
        this.isDeleteFile = true;
      }
      console.log(this.form.documentFile,2222)
      console.log(this.form.documentSrc,3333)
    },

    collectForm() {
      this.form = {
        login: this.getUser?.login,
        email: this.getUser?.email,
        middleName: this.getUser?.middleName,
        phone: formatPhone(this.getUser?.phone),
        city: this.getUser?.city ? {name: this.getUser?.city} : {name: this.getUser?.region.name},
        documentSrc: this.getUser?.documentsSrc,
        documentFile: this.getUser?.documentFile,
        firstName: this.getUser?.firstName,
        birthDate: this.getUser?.birthDate ? new Date(this.getUser?.birthDate) : null,
        lastName: this.getUser?.lastName,
        avatar: {
          filename: this.getUser?.avatar || this.getUser?.avatarSrc.file,
          url: this.getUser?.avatarSrc?.fileSrc,
        },
      },

          console.log(this.form)
      formCopy = JSON.parse(JSON.stringify(this.form))
    },

    submit() {
      this.$v.form.$touch()
      if (!this.$v.form.$anyError || this.isDeleteFile) {
        this.update({
          login: this.form.login,
          email: this.getUser.email,
          middleName: this.form.middleName,
          phone: removeFormat(this.form.phone),
          city: this.form.city.name,
          birthDate: this.dataFormat(),
          documentFile: this.form.documentFile,
          avatar: this.form.avatar.filename,
          role: this.getUser.role,
          documentNumber: this.getUser.documentNumber,
          documentIssueDate: getDate(this.getUser.documentIssueDate),
          firstName: this.form.firstName,
          lastName: this.form.lastName,
          taxNumber: this.getUser.taxNumber,
          companyName: this.getUser.companyName,
        }).then((res) => {
          this.setUserData(res.data)
          this.visibleSuccessAlert = true
          this.isDeleteFile = false;
        }).catch((err) => {
          if(err.response.data.constraint === 'users_login_unique') {
            this.errorEmail = this.$t('nickname-already-exists')
          }
        })
      }
    },

    editProfile () {
      this.visibleSuccessAlert = false
      setTimeout(() => {
        this.$router.push('/profile')
      }, 500)
    },

    getCityApi(query) {
      this.regions = []
      this.isLoadingMultiSelect = true
      axios.get(`https://geocode-maps.yandex.ru/1.x?apikey=bedfc36e-e123-4c97-951b-4de2553423b2&format=json&lang=ru_RU&results=50&locality=kind&geocode=${query}`)
          .then((res) => {
            res.data.response.GeoObjectCollection.featureMember.forEach((val, idx) => {
              const { Address } = val.GeoObject.metaDataProperty.GeocoderMetaData
              if(Address.country_code === 'RU') {
                const obj = {
                  id: idx + 1,
                  name: val.GeoObject.description ? val.GeoObject.description + ',' + val.GeoObject.name : val.GeoObject.name
                }
                this.regions.push(obj)
              }
            })
            this.isLoadingMultiSelect = false
          })
          .catch(() => {
            this.isLoadingMultiSelect = false
          })
    },

    getCityName({ name }) {
      return name
    },
  },

  validations() {
    return {
      form: this.getRules
    }
  },
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
.edit-profile {
  background: #FFFFFF;
  border-radius: 20px;
  max-width: 434px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 30px 34px 40px;
  margin-left: 250px;

  @media (max-width: 992px) {
    max-width: 100%;
    padding: 30px 12px;
    margin-left: 0;
  }

  &__header {
    display: flex;
    gap: 20px;

    &-rang {
      display: flex;
      align-items: center;
      gap: 10px;
      font-weight: 400;
      font-size: 16px;
      line-height: 15px;
      color: #343432;
    }
  }

  &__line {
    border-bottom: 1px solid #D9D9D9;
    margin: 0;
  }

  &__form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    border-radius: 20px;

    &-multiselect {
      display: flex;
      flex-direction: column;
      gap: 10px;
      width: 100%;

      label {
        margin-left: 30px;
        font-size: 12px;
        color: #343432;
      }
    }
  }
}

.upload {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;

  &__title {
    font-weight: 400;
    font-size: 12px;
    line-height: 11px;
    color: #343432;
    margin-left: 30px;
  }

  &__text {
    font-weight: 400;
    font-size: 12px;
    line-height: 11px;
    color: #343432;
    margin-left: 30px;

    &.error {
      color: #E21F1F;
    }

    //&:after {
    //  content: '*';
    //  color: #E21F1F;
    //}
  }

  &__wrap {
    display: flex;
    flex-wrap: nowrap;
    gap: 10px;
    overflow-x: scroll;
    right: 1rem;
    width: 100%;
    height: 100%;
    padding: 10px 0;

    &::-webkit-scrollbar {
      width: 20px;
      height: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background: #9A9A9A;
      border-radius: 10px;
    }

    &-btn {
      width: 60px;
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #E21F1F;
      color: #fff;
      border-radius: 50%;
      cursor: pointer;
    }

    input {
      display: none;
    }

    &-file {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      min-width: 114px;
      max-width: 116px;
      gap: 10px;

      &-close {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        right: 24px;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: #E21F1F;

        img {
          width: 14px;
          height: 14px;
        }
      }

      &-info {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 4px;

        p {
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          text-align: center;
          color: #1B1A1F;
          word-break: break-all;
        }

        span {
          font-weight: 400;
          font-size: 10px;
          line-height: 112.7%;
          color: #BFBDBD;
          width: 58px;
          text-align: center;
        }
      }
    }
  }
}
::v-deep .multiselect__tags {
  border-radius: 25px !important;
  border: 1px solid #9A9A9A !important;
  max-height: 198px !important;
}
::v-deep .multiselect__option {
  font-size: 12px !important;
  font-family: "Gotham Pro Regular";
}
::v-deep .multiselect__content-wrapper {
  border-radius: 10px;
}
::v-deep .multiselect__single {
  font-size: 12px;
  font-family: "Gotham Pro Regular";
}
::v-deep .multiselect__option--selected.multiselect__option--highlight {
  background: none !important;
}
::v-deep .multiselect__option--selected {
  color: #E21F1F !important;
}
::v-deep .multiselect__option--highlight {
  background: none !important;
  color: #E21F1F !important;
}
::v-deep .multiselect__input {
  font-size: 12px !important;
  font-family: "Gotham Pro Regular";
}
::v-deep .multiselect__spinner {
  border-radius: 28px;
}
::v-deep input {
  font-family: "Gotham Pro Regular";
}
.typo__label {
  display: flex;

  &:after {
    content: '*';
    color: #CE2121;

  }
}
</style>
